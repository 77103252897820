import { Directive, Input } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[emailvalidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailValidator,
      multi: true,
    },
  ],
})
export class EmailValidator implements Validator {
  validator: ValidatorFn;

  @Input('emailvalidator') options: any;

  constructor() {
    this.validator = this.emailValidator();
  }

  validate(c: UntypedFormControl) {
    if (!this.options.disabled) {
      return this.validator(c);
    }
  }

  emailValidator(): ValidatorFn {
    return (c: UntypedFormControl) => {
      const checkOther = this.options.hasOwnProperty('other') && this.options.other;
      const isValid =
        !c.value?.length ||
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          c.value.trim(),
        );
      const equal =
        !checkOther ||
        !c.value?.length ||
        c.value.trim().toLowerCase() === this.options?.other?.trim()?.toLowerCase();

      if (isValid && equal) {
        return null;
      } else {
        return {
          emailvalidator: {
            wrongFormat: !isValid,
            notEqual: !equal,
          },
        };
      }
    };
  }
}
