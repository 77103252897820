import { CurrencyPipe } from '@angular/common';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { EventConstants, LogType } from 'global_enums';
import { Address } from 'models/address';
import { Field } from 'models/field';
import { FieldValue } from 'models/field_value';
import {
  ConfirmName,
  LostKeyStep,
  UserActionType,
  OverlayAction,
  OverlayType,
  PassportSetting,
  PmsModType,
  PmsProcess,
  Step as StepEnum,
  CusProductTypes,
} from 'pms_enums';
import { GenericCheckIn } from 'pms_models/generic_check_in';
import { GenericCheckOut } from 'pms_models/generic_check_out';
import { GenericData, SectionHeaderDetails } from 'pms_models/generic_data';
import { GenericOverlay } from 'pms_models/generic_overlay';
import { PmsReservation, Unit } from 'pms_models/pms_reservation';
import {
  BehaviorSubject,
  fromEvent,
  Observable,
  of,
  Subject,
  Subscription,
  timer,
  zip,
} from 'rxjs';
import { delay, filter, finalize, map, shareReplay, skip, startWith, take } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { Business } from 'models/business';
import { LanguageService } from 'common/language/language.service';
import { PmsFolio, PmsFolioInfo } from 'models/pms/pms_folio';
import { StorageService } from 'services/storage.service';
import { PmsGuest } from 'models/pms/pms_guest';
import { PmsService } from './pms.service';
import { merge } from 'lodash';
import * as _ from 'lodash';
import {
  ConfirmSubSteps,
  GuestSubSteps,
  InvoiceSubSteps,
  Stepper,
  StepperActions,
  StepperState,
  TravelInfoSubSteps,
  stepConfigurations,
} from 'models/pms/stepper';
import * as moment from 'moment';
import { Dates, Extras, ReservationCard, Times } from 'models/reservation_card';
import { EventAggregatorService } from 'services/events/event-aggregator.service';

@Injectable({ providedIn: 'root' })
export class PmsCiCoService {
  private static readonly EVENTS = [
    'mousedown',
    'mouseenter',
    'mousemove',
    'scroll',
    'mouseup',
    'click',
    'dblclick',
    'keypress',
    'change',
    'focusin',
    'focusout',
    'input',
    'keydown',
    'keyup',
    'mouseout',
    'mouseover',
  ];

  private subscriptions: Subscription = new Subscription();
  private cusLongLoading: Subscription = new Subscription();
  private longLoading: Subscription = new Subscription();
  private genericData: GenericData;
  private isAwayOverlay: false;
  process: PmsProcess = null;

  stepper: number = null;

  awayTimer: Subscription;
  inactvityObserver: Subscription;
  observerState: any;

  step: Step;
  doorStep = LostKeyStep.lostOrDuplicate;

  hideNext = false;
  preventNext = false;
  disableNext = false;
  hideBack = false;
  disableBack = false;
  should_payment = true;
  late_reg_form = false;
  showContinue = true;
  showSectionHeader = true;
  // show nationality step
  showNationalityStep: boolean = false;

  encoderData: any;
  cardEncoded: boolean;
  cardLost = null;
  confirmed: boolean;
  infoScreen: boolean;
  intervalValue: number;
  timeoutValue: number;
  logUnload = true;
  withOutPMSIsActive = false;
  addressChange = false;
  folioAddressStep = 'folio';
  displayedFolioIndex = -1;
  cusProducts: { room: any | null; breakfast: any | null } = { room: null, breakfast: null };
  cusRoomupgradeToBook: { offer: any; ruleId: number; product: any } | null = null;
  cusBreakfastToBook: { product: any; ruleId: number } | null = null;
  skipCi = false;

  hasBookedRoomupgrade: boolean;
  hideAddressList: boolean;
  steps: string[] = [];
  events: string[] = [];
  showFooter: boolean;

  loadingError: any;

  loadedSubj: Subject<boolean> = new Subject<boolean>();
  loaded: Observable<boolean> = this.loadedSubj.asObservable();

  loggedInSubj: Subject<any> = new Subject<any>();
  loggedIn: Observable<any> = this.loggedInSubj.asObservable();

  inactivitySubj: Subject<boolean> = new Subject<boolean>();
  inactivity: Observable<boolean> = this.inactivitySubj.asObservable();

  idleSubj: Subject<boolean> = new Subject<boolean>();
  idle: Observable<boolean> = this.idleSubj.asObservable();

  dataSubj: BehaviorSubject<GenericData> = new BehaviorSubject<GenericData>(null);
  data: Observable<GenericData> = this.dataSubj.asObservable();

  confirmNameSubj: BehaviorSubject<ConfirmName> = new BehaviorSubject<ConfirmName>(
    ConfirmName.next,
  );
  confirmName: Observable<ConfirmName> = this.confirmNameSubj.asObservable();

  suppressGuardSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  suppressGuard: Observable<boolean> = this.suppressGuardSubj.asObservable();

  folioUpdateSubj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  folioUpdate: Observable<any> = this.folioUpdateSubj.asObservable();

  overlayOpenSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  overlayOpen: Observable<boolean> = this.overlayOpenSubj.asObservable();

  sendSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  send: Observable<boolean> = this.sendSubj.asObservable();

  private navigationSubj: Subject<UserActionType> = new Subject<UserActionType>();
  navigation: Observable<UserActionType> = this.navigationSubj.asObservable();

  headerActionSubj: Subject<UserActionType> = new Subject<UserActionType.cancel>();
  headerAction: Observable<UserActionType> = this.headerActionSubj.asObservable();

  private overlaySubj: Subject<GenericOverlay> = new Subject<GenericOverlay>();
  overlay: Observable<GenericOverlay> = this.overlaySubj.asObservable();

  private overlayCloseSubj: Subject<{ action: OverlayAction; guard: boolean }> = new Subject<{
    action: OverlayAction;
    guard: boolean;
  }>();
  overlayClose: Observable<{ action: OverlayAction; guard: boolean }> =
    this.overlayCloseSubj.asObservable();

  autoSkipUntilVirtualGuestStep: number | null = null;

  cusLoadedSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  cusLoaded: Observable<boolean> = this.cusLoadedSubj.asObservable();

  fieldsUpdatedSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  fieldsUpdated: Observable<boolean> = this.fieldsUpdatedSubj.asObservable();

  loadInitialTrans: boolean;
  totalGrossService = '';
  deviatingBillingAddress: undefined | null | true;

  keyboardOpenSubj: Subject<boolean> = new Subject<boolean>();

  /** Array of steps for the user to navigate through in the UI, each step represented by a 'Stepper' object.*/
  public userSteps: Stepper[] = [];
  /** The current active step for the user in the navigation process represented by a 'Stepper' object*/
  public activeStep: Stepper;

  public autoSkipUntilStep: StepEnum | null = null;

  public subStepForGuest: string = GuestSubSteps.primaryGuest;

  sectionDetails: SectionHeaderDetails = new SectionHeaderDetails();

  addresses: Address[] = [];

  constructor(
    private api: ApiService,
    private globals: Globals,
    private businessService: BusinessService,
    private languageService: LanguageService,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    public pmsService: PmsService,
    private storageService: StorageService,
    private readonly eventService: EventAggregatorService,
  ) {}

  addSubscriptions() {
    // Subscribe self to update genericData
    this.subscriptions.add(
      this.data.pipe(filter(Boolean)).subscribe((data: GenericData) => {
        this.genericData = data;
      }),
    );

    this.subscriptions.add(
      this.idle.pipe(filter(Boolean)).subscribe(() => {
        this.stopAwayTimer();
      }),
    );

    this.subscriptions.add(
      this.overlayClose.subscribe((close: any) => {
        if (this.isAwayOverlay && close.action === OverlayAction.close) {
          this.addEventListeners(this);
          this.isAwayOverlay = false;
          this.resetAway(this);
        }
      }),
    );
  }

  setSteps(data = this.genericData) {
    this.genericData = data;
    if (this.genericData) {
      this.setStepsWithData();
    } else {
      this.subscriptions.add(
        this.data.pipe(filter(Boolean)).subscribe((genericData: GenericData) => {
          this.genericData = genericData;
          this.setStepsWithData();
        }),
      );
    }
  }

  orderCountriesInSearch(list: any[], searchValue: string): any[] {
    return list
      .filter((option) => option.name.toLowerCase().includes(searchValue.toLowerCase()))
      .sort((a, b) => {
        const indexOfA = a.name.toLowerCase().indexOf(searchValue.toLowerCase());
        const indexOfB = b.name.toLowerCase().indexOf(searchValue.toLowerCase());
        return indexOfA - indexOfB;
      })
      .slice(0, 9);
  }

  loadFolios(data: GenericData, cus = false) {
    data.folioInfo.loaded.next(false);
    data.folioInfo.longLoading.next(false);
    data.folioInfo.error = undefined;

    if (cus) {
      this.cusLongLoading = timer(8000).subscribe(() => {
        data.folioInfo.cusLongLoading.next(true);
      });
    } else {
      this.longLoading = timer(20000).subscribe(() => {
        data.folioInfo.longLoading.next(true);
      });
    }

    this.pmsService
      .getFolios(
        data.incident.reservation.uuid,
        data.incident.reservation.payment_token,
        data.module.type,
      )
      .subscribe(
        (raw_folios: any) => {
          const folios = this.mapFolios(raw_folios.folios || [], data.business);

          data.incident.reservation.allFolios = folios;
          if (data.business.usePms('suite8')) {
            const list = folios.filter((folio) => folio.valid());
            data.incident.reservation.folios = list.length
              ? list
              : new Array(folios[folios.length - 1]);
          } else {
            data.incident.reservation.folios = folios.filter((folio) => folio.valid());
          }

          data.setFolios(folios);
          data.folioInfo.loaded.next(true);
          data.folioInfo.longLoading.next(false);
          data.folioInfo.cusLongLoading.next(false);
          data.folioInfo.error = false;
          data.incident.checks.loaded_folios = true;
          if (data.module.type === PmsModType.ci) {
            data.module.settings.travel_purpose_lock = folios.some(
              (folio) => folio.existing_payments,
            );
          }
        },
        () => {
          data.folioInfo.loaded.next(true);
          data.folioInfo.longLoading.next(false);
          if (data.isReservationModule()) {
            data.folioInfo.noFolios = true;
          } else {
            data.folioInfo.error = true;
          }
        },
        () => {
          this.longLoading?.unsubscribe();
          this.cusLongLoading?.unsubscribe();
        },
      );
  }

  mapFolios(raw_folios, business: Business): PmsFolio[] {
    const folios = raw_folios.map((folio) => new PmsFolio(folio));
    folios?.forEach((folio) => {
      folio.balance_with_currency = this.currencyPipe.transform(
        folio.payable_balance,
        business.currency,
      );
      folio.pre_auth_balance_with_currency = this.currencyPipe.transform(
        folio.pre_auth_balance,
        business.currency,
      );
    });
    return folios;
  }

  setStepsWithData() {
    if (!this.genericData?.isReservationModule()) {
      if (this.genericData.module.type === PmsModType.ci) {
        this.reservationStepCheck();
      }
      this.invoiceCheck();
    }
  }

  fetchData(uuid: string, module: PmsModType): void {
    this.loadedSubj.next(false);
    this.globals.reservationUuid = uuid;
    this.disableNextButton(false);
    const querySub = this.router.routerState.root.queryParams.subscribe((query) => {
      if (uuid || !this.globals.business.usePms()) {
        this.globals.reservationUuid = uuid;
        this.fetchingData(uuid, module, query);
      } else if (!uuid) {
        this.subscriptions.add(
          this.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
            uuid = data.incident.reservation.uuid;
            this.globals.reservationUuid = uuid;
            this.fetchingData(uuid, module, query);
          }),
        );
      }
      setTimeout(() => {
        querySub?.unsubscribe();
      });
    });
  }

  fetchingData(uuid: string, module: PmsModType, query) {
    if (query?.params) {
      this.globals.log('init from Online Cache', LogType.info, true);
      this.initFromOnlineCache(uuid, query.params);
    } else {
      zip(
        this.globals.getModule(module, true, true),
        this.businessService.current_business.pipe(filter(Boolean), take(1)),
        this.api.silentGet('countries'),
        this.getIncident(uuid, module),
      )
        .pipe(
          map((result) => {
            return new GenericData({
              module: result[0],
              business: result[1],
              countryData: result[2],
              incident: result[3],
            });
          }),
        )
        .subscribe(
          (res) => {
            this.storageService
              .getItem(res.incident.getStorageKey(), this.globals.code)
              .then((item) => {
                if (module === PmsModType.ci || module === PmsModType.co) {
                  merge(res.incident, item.incident);
                }
              })
              .finally(() => {
                this.init(res);
                this.suppressGuardSubj.next(true);
              });
          },
          () => {
            this.suppressGuardSubj.next(true);
            this.loadedSubj.next(true);
          },
        );
    }
  }

  setProcess(process: PmsProcess) {
    this.process = process;
  }

  getProcess(): PmsProcess {
    return this.process;
  }

  stopAwayTimer(): number {
    if (this.awayTimer) {
      this.awayTimer.unsubscribe();
      this.subscriptions.remove(this.awayTimer);
    }
    if (this.inactvityObserver) {
      this.inactvityObserver.unsubscribe();
      this.subscriptions.remove(this.inactvityObserver);
    }
    return this.timeoutValue;
  }

  openOverlay(
    type: OverlayType,
    title?: string,
    content?: string,
    isGuard?: boolean,
    inputParams?: any,
  ) {
    this.overlaySubj.next(
      this.overlayContent(type, title, content, isGuard, undefined, undefined, inputParams),
    );
    window.scrollTo(0, 0);
  }

  openComponentOverlay(component: any, inputParams?: {}, id?: string, with_back?: boolean) {
    this.overlaySubj.next(
      this.overlayContent(
        OverlayType.component,
        null,
        null,
        null,
        with_back,
        component,
        inputParams,
        id,
      ),
    );
    window.scrollTo(0, 0);
  }

  closeOverlay(action: OverlayAction, guard: boolean = false) {
    this.overlayCloseSubj.next({ action: action, guard: guard });
    if (action !== OverlayAction.close) {
      document.getElementById('container')?.classList?.remove('frame', 'overlay');
    }
  }

  reservationStepCheck() {
    const fields = this.genericData.module.usedFields().some((ident) => this.field_for(ident));
    const subFields = this.genericData.module
      .usedSubFields()
      .some((ident) => this.field_for('reservation_infos')?.subField(ident));
    const userFields = this.genericData.module.usableFields().length > 0;
    this.step.reservation =
      fields || subFields || userFields || !!this.cusProducts.room || this.genericData.blank;
  }

  setAutoSkipUntilVirtualGuestStep(next: number | null) {
    this.autoSkipUntilVirtualGuestStep = next;
  }

  getAutoSkipUntilVirtualGuestStep(): number {
    return this.autoSkipUntilVirtualGuestStep;
  }

  setPassportVisa() {
    this.subscriptions.add(
      this.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
        const passportSettings = data.module.settings.passport;

        const primaryPassportField = this.field_for('primary_guest')?.subField('passport');
        const fellowsAdultPassportField = this.field_for('fellows')
          ?.subField('adult_fellows')
          ?.subField('passport');
        const fellowsKidsPassportField = this.field_for('fellows')
          ?.subField('children_fellows')
          ?.subField('passport');

        const primaryVisaField = this.field_for('primary_guest')?.subField('visa');
        const fellowsAdultVisaField = this.field_for('fellows')
          ?.subField('adult_fellows')
          ?.subField('visa');
        const fellowsKidsVisaField = this.field_for('fellows')
          ?.subField('children_fellows')
          ?.subField('visa');

        if (
          (passportSettings &&
            (primaryPassportField || fellowsAdultPassportField || fellowsKidsPassportField)) ||
          primaryVisaField ||
          fellowsAdultVisaField ||
          fellowsKidsVisaField
        ) {
          data.incident.reservation.allGuests().forEach((guest: PmsGuest) => {
            const primary = guest === data.incident.reservation.primary_guest;

            const passportField = primary
              ? primaryPassportField
              : guest.adult()
                ? fellowsAdultPassportField
                : fellowsKidsPassportField;
            if (passportSettings && passportField && passportField.fields.length) {
              const foreigner = !guest.domestic(this.globals.business);

              const passportSetting =
                passportSettings[
                  primary ? 'primary' : guest.adult() ? 'fellow_adult' : 'fellow_children'
                ];
              if (passportSetting) {
                const info =
                  passportSetting['for'] === PassportSetting.all ||
                  (foreigner && passportSetting['for'] === PassportSetting.foreigner);
                guest.passport_data = <boolean>info;
                guest.valid_documents = passportSetting['valid_documents'];
              }

              const image_field = passportField.subField('passport_image');
              const uploadable = image_field
                ?.setting('uploadable_for')
                ?.includes(foreigner ? 'foreigners' : 'domestic');
              const required_foreigner = uploadable && image_field?.setting('required_foreigners');
              const required_domestic = uploadable && image_field?.setting('required_domestic');
              guest.passport_upload_required = image_field
                ? foreigner
                  ? required_foreigner
                  : required_domestic
                : false;
              guest.passport_image =
                uploadable && image_field && (!primary || !data.incident.reservation.authenticated);

              if (
                (guest.passport_image || guest.passport_data) &&
                guest.valid_documents &&
                guest.valid_documents.length === 1
              ) {
                guest.doc_type = 'pass';
              }
            }

            const visaField = primary
              ? primaryVisaField
              : guest.adult()
                ? fellowsAdultVisaField
                : fellowsKidsVisaField;
            if (visaField) {
              const countryMatch = visaField
                .setting('visa_nationalities')
                ?.includes(guest.nationality);
              guest.visa_data =
                countryMatch &&
                (visaField?.subField('visa_number')?.active ||
                  visaField?.subField('visa_date')?.active ||
                  visaField?.subField('visa_expire')?.active);
            }
          });
        }
      }),
    );
  }

  ui_messages(): any {
    return (this.genericData?.module || this.globals._module)?.settings?.ui_messages || {};
  }

  field_for(kind): Field {
    return (this.genericData?.module || this.globals._module)?.field(kind);
  }

  updatedFields() {
    if (this.genericData.module.settings.check_changes) {
      this.fetchFieldsFromApi();
    }
  }

  fetchFieldsFromApi() {
    const params = {
      id: this.genericData.module.type,
      uuid: this.genericData.incident.reservation.uuid,
      field_values: this.fieldValues(),
    };
    this.api.post('module', params).subscribe(
      (response: any) => {
        this.genericData.module.fields = response.fields.map((field) => new Field(field));
        this.fieldsUpdatedSubj.next(true);
      },
      (error) => {
        console.log(error);
      },
    );
  }

  fieldValues() {
    const guest = this.genericData.incident.reservation.primary_guest;
    return {
      primary_guest: {
        gender: guest.gender,
        nationality: guest.nationality,
        date_of_birth: guest.date_of_birth,
      },
      address: { country: guest.address.country },
      reservation: { travel_purpose: this.genericData.incident.reservation.travel_purpose },
    };
  }

  setAddressName() {
    const primaryGuest = this.genericData.incident.reservation.primary_guest;
    primaryGuest.address.first_name = primaryGuest.first_name;
    primaryGuest.address.last_name = primaryGuest.last_name;

    if (this.genericData.incident.reservation.address?.isSame(primaryGuest.address)) {
      this.genericData.incident.reservation.address.first_name = primaryGuest.first_name;
      this.genericData.incident.reservation.address.last_name = primaryGuest.last_name;
    }
  }

  invoiceCheck() {
    const settings = this.genericData.module.settings ?? {};
    const folioSettings = settings.invoice && settings.viewable_folios;
    if (this.genericData.module.type === PmsModType.ci) {
      this.step.invoice =
        !this.genericData.blank &&
        ((folioSettings && this.hasFolios()) ||
          this.genericData?.incident?.reservation?.pre_auth?.auth_without_folio);
    } else if (this.genericData.module.type === PmsModType.co) {
      this.genericData.incident.checks.paid_folios = this.foliosPaid();
      this.step.invoice = folioSettings && this.hasFolios();
    }
  }

  hasFolios(): boolean {
    if (this.genericData.folioInfo.error || this.genericData.folioInfo.error === undefined) {
      return true;
    } else {
      return (this.genericData.incident.reservation?.folios || []).length > 0;
    }
  }

  foliosPaid(): boolean {
    const folios = this.genericData.incident.reservation.allFolios;
    return this.genericData.folioInfo.loaded && folios?.every((folio) => folio.paid(true));
  }

  disableButtons(disable) {
    setTimeout(() => {
      this.disableNext = this.disableBack = disable;
    });
  }

  disableNextButton(disable) {
    of(true)
      .pipe(delay(0), take(1))
      .subscribe(() => {
        this.disableNext = disable;
      });
  }

  showContinueButton(show: boolean) {
    of(true)
      .pipe(delay(0), take(1))
      .subscribe(() => {
        this.showContinue = show;
      });
  }

  setFolioAddressStep(step: string) {
    of(true)
      .pipe(delay(0), take(1))
      .subscribe(() => {
        this.folioAddressStep = step;
      });
  }

  setAutoSkipUntilStep(step: any) {
    of(true)
      .pipe(delay(0), take(1))
      .subscribe(() => {
        this.autoSkipUntilStep = step;
      });
  }

  setShowFooter(show) {
    of(true)
      .pipe(delay(0), take(1))
      .subscribe(() => {
        this.showFooter = show;
      });
  }

  hideNextButton(hide) {
    of(true)
      .pipe(delay(0), take(1))
      .subscribe(() => {
        this.hideNext = hide;
      });
  }

  hideBackButton(hide) {
    of(true)
      .pipe(delay(0), take(1))
      .subscribe(() => {
        this.hideBack = hide;
      });
  }

  private init(data: GenericData) {
    document.getElementById('container')?.classList?.add('cico');
    this.globals.removeQuery();
    this.initSteps(data);
    this.setSteps(data);
    this.globals.viewSubj.next(data.module.type);

    // Initializes or updates the userSteps array.
    // This assignment updates userSteps with the latest set of active steppers.
    this.generateActiveSteppers(this.step, !data.blank);

    if (!data.blank) {
      this.loadFolios(data);
    }

    this.loggedInSubj.next(true);
    this.subscriptions.add(
      this.businessService.currentLocale.pipe(skip(1)).subscribe((_locale) => {
        if (this.loadInitialTrans) {
          this.pmsService
            .get_texts(data.module.type, data.incident.reservation.uuid)
            .subscribe((updated: any) => {
              data.module.fields.forEach((field, index) => {
                this.updateFields(field, updated.module.fields[index]);
              });
              data.module.settings.ui_messages = updated.module.settings.ui_messages;
              this.logUnload = !data.isReservationModule();
              this.dataSubj.next(data);
            });
          this.loadInitialTrans = false;
        }
      }),
    );

    this.dataSubj.next(data);
    this.loadedSubj.next(true);
    this.toggleInactivity(true);
  }

  updateFields(target, source) {
    if (source.fields && source.fields.length > 0) {
      target.fields = target.fields.map((field, index) => {
        if (source.fields[index]) {
          field.name = source.fields[index].name;
          this.updateFields(field, source.fields[index]);
        }
        return field;
      });
    }
  }

  localSaveProcess(data: GenericData) {
    const modType = data?.module?.type;
    if (
      !data ||
      this.autoSkipUntilStep ||
      (modType !== PmsModType.ci && modType !== PmsModType.co)
    ) {
      return;
    }

    const incident = _.cloneDeep(data.incident);
    [
      'allFolios',
      'folios',
      'pre_auth',
      'can_check_in',
      'can_check_out',
      'medias',
      'booking_data',
      'in_time',
      'authenticate',
      'authenticated',
      'should_sign',
      'payment',
      'payment_providers',
      'skipable',
      'adults',
      'children',
      'total_price',
      'room',
      'unit_name',
      'unit',
      'auth_url',
      'authenticate',
      'pre_auth',
      'services',
      'reception',
      'unit',
      'arrival',
      'departure',
    ].forEach((attr) => {
      delete incident.reservation[attr];
    });
    ['prevention', 'prevented'].forEach((attr) => {
      delete incident[attr];
    });

    const item = { incident: incident, updated_at: new Date().getTime(), code: this.globals.code };
    this.storageService.setItem(incident.getStorageKey(), item);

    const incidentsPrefix = (modType === PmsModType.ci ? 'ci_' : 'co_') + 'incident_';
    this.storageService.keepOnlyNewest(incidentsPrefix, 3, data.business.code);
  }

  private initFromOnlineCache(uuid: string, token: string) {
    this.pmsService.getOnlineCache(token).subscribe(
      (response: any) => {
        this.languageService.setLocale(response.locale);
        const data = new GenericData().copyData(response);
        data.folioInfo.error = undefined;
        this.globals.getModule(data.module.type).then((mod) => {
          this.businessService.current_business
            .pipe(filter(Boolean), take(1))
            .subscribe((business: Business) => {
              data.module = mod;
              data.business = business;
              data.folioInfo = new PmsFolioInfo();
              data.incident = this.setIncident(data.incident, data.module.pmsType());
              data.reservation = new PmsReservation(data.incident.reservation);
              data.reservation.address = new Address(data.incident.reservation.address);
              if (data.incident.reservation.uuid === uuid) {
                this.init(data);
              } else {
                this.cancelInitFromCache();
              }
            });
        });
      },
      () => {
        this.cancelInitFromCache();
      },
    );
  }

  private cancelInitFromCache(): void {
    this.showContinueButton(true);
    this.suppressGuardSubj.next(true);
    this.globals.navigate('home');
  }

  private getIncident(uuid: string, module: string) {
    return new Promise<any>((resolve, reject) => {
      const mod = module.replace('pms_', '');
      this.pmsService.get_reservation(uuid, mod).subscribe(
        (reservation: any) => {
          resolve(this.setIncident(reservation, mod));
        },
        (response) => {
          this.disableNextButton(true);
          if (response?.error?.type) {
            this.loadingError = response.error;
          }
          reject();
        },
      );
    });
  }

  private setIncident(lsIncident: any, mod: string) {
    const incident =
      mod === 'check_in' ? new GenericCheckIn(lsIncident) : new GenericCheckOut(lsIncident);
    this.field_values(lsIncident.reservation, incident);
    return incident;
  }

  private field_values(reservation, incident) {
    if (reservation.field_values) {
      reservation.field_values.forEach((field_value: any) => {
        const field = incident.field_values.find((ifield) => ifield.id === field_value.id);
        if (field) {
          field.value = field_value.value;
        } else {
          incident.field_values.push(
            new FieldValue({
              id: field_value.id,
              value: field_value.value,
              check_out_if: field_value.check_out_if,
            }),
          );
        }
      });
    }
  }

  // Cross & Upsell
  loadCus(uuid) {
    this.globals
      .getModule(PmsModType.service, false)
      .then((_mod) => {
        this.pmsService
          .getServices(false, uuid)
          .pipe(finalize(() => this.cusLoadedSubj.next(true)))
          .subscribe(
            (rules: any) => {
              this.cusProducts.breakfast =
                rules.find((rule) => rule.service_type === CusProductTypes.breakfast) || null;
              this.cusProducts.room =
                rules.find((rule) => rule.service_type === CusProductTypes.roomCategory) || null;

              if (this.cusProducts.breakfast) {
                this.addSubstep(StepEnum.reservation, TravelInfoSubSteps.cusBreakfast);
              } else {
                this.removeSubstep(StepEnum.reservation, TravelInfoSubSteps.cusBreakfast);
              }
            },
            () => {},
          );
      })
      .catch(() => {
        this.cusLoadedSubj.next(true);
      });
  }

  // Init steps

  private initSteps(data) {
    if (data.module.type === PmsModType.ci) {
      this.step = new Step(true, true, true, true);
    } else if (data.module.type === PmsModType.co) {
      this.step = new Step(false, true, true, true);
    } else {
      this.step = new Step(false, false, false, false);
    }
  }

  // Caching

  cacheImage(type, uuid, base64) {
    this.pmsService.cacheImageUpload(type, uuid, base64).subscribe(
      () => {},
      () => {},
    );
  }

  // Navigation

  navigate(type: UserActionType) {
    this.navigationSubj.next(type);
  }

  scrollToFolio(folio: PmsFolio, instant = false) {
    const container = document.querySelectorAll(`[data-folio='${folio.number}']`)[0];
    container?.scrollIntoView({
      behavior: <any>(instant ? 'instant' : 'smooth'),
      block: 'center',
      inline: 'start',
    });
  }

  // Timer

  initiateAway(timeout: number) {
    if (this.globals.kiosk()) {
      this.intervalValue = this.timeoutValue = timeout;
      this.toggleInactivity(true);
      this.observeInactivity(this);
      this.observerState ? this.startTimer() : this.stopAwayTimer();
      this.addEventListeners(this);
    }
  }

  toggleInactivity(enable) {
    if (this.globals.kiosk() && this.observerState !== enable) {
      this.observerState = enable;
      this.globals.log('inactivity subscription: ' + enable, LogType.info, true);
      if (!enable) {
        this.stopAwayTimer();
      } else if (enable && this.inactvityObserver) {
        this.startTimer();
      }
    }
  }

  private startTimer() {
    const self = this;
    const awayTrigger = this.timeoutValue <= 30 ? Math.ceil(this.timeoutValue / 2) : 30;

    this.awayTimer?.unsubscribe();
    this.awayTimer = timer(1000, 1000).subscribe(() => {
      self.intervalValue -= 1;
      if (environment.name === 'ickarus') {
        console.info('Timeout in', self.intervalValue);
      }
      if (self.intervalValue === awayTrigger) {
        this.inactivitySubj.next(true);
      } else if (self.intervalValue === 0) {
        this.inactive(self);
        this.localSaveProcess(this.genericData);
        this.closeLog('awaytimer');
      }
    });
  }

  private observeInactivity(self) {
    this.inactvityObserver = fromEvent(document, 'visibilitychange')
      .pipe(
        map((_x) => document.visibilityState),
        startWith('visible'),
        shareReplay(1),
      )
      .subscribe((state) => {
        if (state === 'hidden') {
          this.inactive(self);
        }
      });
  }

  private inactive(self) {
    this.stopAwayTimer();
    this.inactivitySubj.next(false);
    self.idleSubj.next(true);
    document.getElementById('container')?.classList?.add('wizard', 'overlay');
  }

  private addEventListeners(self) {
    PmsCiCoService.EVENTS.forEach((type) => {
      if (!this.events.includes(type)) {
        this.events.push(type);
        document.addEventListener(type, (event: any) => {
          if (
            self.intervalValue !== 0 &&
            self.timeoutValue &&
            (type === 'mousemove' || !event.toElement?.classList?.contains('background'))
          ) {
            self.resetAway(self);
            self.inactivitySubj.next(false);
          }
        });
      }
    });
  }

  private resetAway(self) {
    self.intervalValue = self.timeoutValue;
  }

  private overlayContent(
    type: OverlayType,
    title?: string,
    content?: string,
    isGuard?: boolean,
    with_back?: boolean,
    component?: any,
    inputParams?: {},
    id?: string,
  ) {
    const cico = this.genericData?.module?.pmsType() || 'login';

    switch (type) {
      case OverlayType.cancel:
        return new GenericOverlay(
          this.globals.translate(`service.${cico}.overlay.cancelTitle`),
          { text: this.globals.translate(`service.${cico}.overlay.cancelDescription`) },
          {
            title: this.globals.translate(`service.${cico}.overlay.cancel_close`),
            action: OverlayAction.close,
          },
          {
            title: this.globals.translate(`service.general.save_and_cancel`),
            action: OverlayAction.cancel,
            guard: isGuard,
          },
          with_back,
        );
      case OverlayType.finish:
        return new GenericOverlay(
          this.globals.translate(`service.login.overlay.finishTitle`),
          null,
          { title: this.globals.translate(`misc.no_string`), action: OverlayAction.close },
          {
            title: this.globals.translate(`misc.yes_string`),
            action: OverlayAction.cancel,
            guard: isGuard,
          },
          with_back,
        );
      case OverlayType.confirm:
        return new GenericOverlay(
          this.globals.translate(title),
          content ? { text: content, class: content.length > 7 ? 'medium' : 'huge' } : null,
          { title: this.globals.translate('misc.close'), action: OverlayAction.cancel },
          null,
          with_back,
          null,
          null,
          'success',
        );
      case OverlayType.skip:
        return new GenericOverlay(
          this.globals.translate('service.check_in.overlay.skipTitle'),
          content ? { text: content } : null,
          { title: this.globals.translate('misc.back'), action: OverlayAction.close },
          { title: this.globals.translate('misc.skip'), action: OverlayAction.skip },
          with_back,
        );
      case OverlayType.skipCi:
        return new GenericOverlay(
          this.globals.translate(title),
          { text: this.globals.translate(content) },
          {
            title: this.globals.translate('service.check_in.overlay.review'),
            action: OverlayAction.closeSkipCi,
          },
          { title: this.globals.translate('misc.skip'), action: OverlayAction.skipCi },
          with_back,
        );
      case OverlayType.reallySure:
        return new GenericOverlay(
          this.globals.translate('pms_door.terminal.reallySure.title'),
          { text: this.globals.translate('pms_door.terminal.reallySure.description') },
          { title: this.globals.translate('misc.no_string'), action: OverlayAction.close },
          { title: this.globals.translate('misc.yes_string'), action: OverlayAction.reallySure },
          with_back,
        );
      case OverlayType.addToFolios:
        return new GenericOverlay(
          this.globals.translate('cus.add_product'),
          { text: this.globals.translate(title) + this.globals.translate(content) },
          { title: this.globals.translate('misc.cancel'), action: OverlayAction.close },
          { title: this.globals.translate('misc.confirm'), action: OverlayAction.addToFolios },
          with_back,
          undefined,
          inputParams,
        );
      case OverlayType.noCard:
        return new GenericOverlay(
          this.globals.translate('pms_door.terminal.noCard.title'),
          { text: this.globals.translate('pms_door.terminal.noCard.description') },
          {
            title: this.globals.translate('misc.yes_string'),
            action: OverlayAction.cancel,
            guard: isGuard,
          },
          { title: this.globals.translate('misc.back'), action: OverlayAction.close },
          with_back,
        );
      case OverlayType.reset:
        return new GenericOverlay(
          this.globals.translate('service.check_in.restart.title'),
          { text: this.globals.translate('service.check_in.restart.description') },
          {
            title: this.globals.translate('misc.restart'),
            action: OverlayAction.reload,
            guard: isGuard,
          },
          null,
          with_back,
        );
      case OverlayType.component:
        return new GenericOverlay(null, null, null, null, with_back, component, inputParams, id);
      default:
        break;
    }
  }

  closeLog(reason) {
    if (this.genericData?.logable()) {
      const time = this.duration();
      if (time.seconds > 10 && time.seconds < 1800) {
        this.api
          .silentPost('incident/destroy', {
            module: this.genericData.module.type,
            params: Object.assign(this.genericData.incident),
            reason: reason,
            step: this.genericData.step,
            duration: time.nano,
          })
          .subscribe(
            () => {},
            () => {},
          );
      }
    }
  }

  duration(): any {
    const time = Date.now() - this.genericData.startTime;
    this.genericData.startTime = null;
    return { nano: time * 1000000, seconds: time / 1000 };
  }

  removeSubscriptions(): void {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
  }

  /**
   * Returns the appropriate validation message based on the provided validation errors.
   *
   * @param values - An array of validation error strings.
   * @returns The corresponding validation message key.
   */
  getDatePickerValidationMessage(values: string[]): string {
    const validationMessages: { [key: string]: string } = {
      required: 'validation.required',
      future: 'validation.date_future',
      past: 'validation.date_past',
      adult: 'validation.adult_date_of_birth',
      kids: 'validation.kids_date_of_birth',
    };

    for (const key in validationMessages) {
      if (values.includes(key)) {
        return validationMessages[key];
      }
    }

    return 'misc.invalid_date';
  }

  hasValidationMessages(event: any): boolean {
    return event?.detail?.validationMessages && event.detail.validationMessages.length > 0;
  }

  /**
   * Creates Stepper objects for active steps based on the 'step' object.
   * @param stepConditions Object mapping step keys to boolean indicating if they are active.
   */
  generateActiveSteppers(stepConditions: Step = undefined, isPMS: boolean = true) {
    let activeKeys = this.generateActiveStepKeys(stepConditions);
    if (!isPMS) {
      activeKeys = this.reorderStepperForNoPMS(activeKeys);
    }
    const initialSubsteps = {
      guests: GuestSubSteps.primaryGuest,
      reservation: TravelInfoSubSteps.reservation,
      invoice: InvoiceSubSteps.invoice,
      confirm: ConfirmSubSteps.confirm,
    };

    this.userSteps = activeKeys.map((key) => ({
      key: key as StepEnum,
      icon: stepConfigurations[key].icon,
      subSteps: [initialSubsteps[key] || 'unknown'],
      totalSteps: 1,
      currentStep: 1,
      state: StepperState.disabled,
      action: stepConfigurations[key].action,
    }));

    // Set the state of the first active step to active only if its configured state allows it and no active state is already set.
    if (
      this.userSteps.length > 0 &&
      !this.userSteps.find((step) => step.state === StepperState.active)
    ) {
      this.userSteps[0].state = StepperState.active;
    }

    this.setActiveStepper();
  }

  /** Reset to initial state  */
  resetUserSteps() {
    this.userSteps.forEach((step) => {
      step.state = StepperState.disabled;
      step.action = StepperActions.none;
      step.currentStep = 1;
    });

    if (this.userSteps.length > 0) {
      this.userSteps[0].state = StepperState.active;
    }

    this.subStepForGuest = GuestSubSteps.primaryGuest;

    this.setActiveStepper();
  }

  /**
   * Increments the total steps for a specified step.
   * If the step is found, its totalSteps are incremented; otherwise, no action is taken.
   *
   * @param key The unique key of the step whose totalSteps is to be updated.
   * @param totalSteps The number to add to the existing totalSteps of the step.
   */
  updateTotalSteps(key: string, totalSteps: number): void {
    const userStep = this.userSteps.find((step) => step.key === key);

    if (userStep) {
      userStep.totalSteps = totalSteps;
      userStep.subSteps = Array(totalSteps).fill('unknown');
    }
  }

  /**
   * Adds a substep Increments the total steps for a specified step.
   * If the step is found, its totalSteps are incremented; otherwise, no action is taken.
   *
   * @param key The unique key of the step whose totalSteps is to be updated.
   * @param totalSteps The number to add to the existing totalSteps of the step.
   */
  addSubstep<T extends GuestSubSteps | TravelInfoSubSteps | 'unknown'>(
    key: StepEnum,
    substepName: T,
  ): void {
    const userStep = this.userSteps.find((step) => step.key === key);
    if (!userStep) {
      return;
    }
    userStep.subSteps.push(substepName);
    userStep.totalSteps = userStep.subSteps.length;
    if (substepName === 'unknown') {
      return;
    }
    this.sortSubsteps(userStep);
  }

  removeSubstep<T extends GuestSubSteps | TravelInfoSubSteps | 'unknown'>(
    key: StepEnum,
    substepToDelete: T,
  ): void {
    const userStep = this.userSteps.find((step) => step.key === key);
    if (!userStep) {
      return;
    }
    const updatedSubsteps = userStep.subSteps.filter((substep) => substep !== substepToDelete);
    userStep.subSteps = updatedSubsteps;
    userStep.totalSteps = userStep.subSteps.length;
  }

  /**
   * Sorts the given userStep substeps according to the order of substeps mentioned in the enumeration
   * @param userStep
   */
  sortSubsteps(userStep: Stepper): void {
    switch (userStep.key) {
      case StepEnum.reservation:
        const travelInfoOrder = Object.values(TravelInfoSubSteps);
        userStep.subSteps.sort(
          (a, b) =>
            travelInfoOrder.indexOf(a as TravelInfoSubSteps) -
            travelInfoOrder.indexOf(b as TravelInfoSubSteps),
        );
        break;
      case StepEnum.confirm:
        const ConfirmSubstepsOrder = Object.values(ConfirmSubSteps);
        userStep.subSteps.sort(
          (a, b) =>
            ConfirmSubstepsOrder.indexOf(a as ConfirmSubSteps) -
            ConfirmSubstepsOrder.indexOf(b as ConfirmSubSteps),
        );
        break;
      case StepEnum.guests:
        const GuestSubstepsOrder = Object.values(GuestSubSteps);
        userStep.subSteps.sort(
          (a, b) =>
            GuestSubstepsOrder.indexOf(a as GuestSubSteps) -
            GuestSubstepsOrder.indexOf(b as GuestSubSteps),
        );
        break;
      case StepEnum.invoice:
        const invoiceOrder = Object.values(InvoiceSubSteps);
        userStep.subSteps.sort(
          (a, b) =>
            invoiceOrder.indexOf(a as InvoiceSubSteps) - invoiceOrder.indexOf(b as InvoiceSubSteps),
        );
        break;
    }
  }

  getCurrentStepName() {
    return this.activeStep.subSteps[this.activeStep.currentStep - 1];
  }

  /**
   * Set the active step for the current user from an array of steps.
   */
  setActiveStepper() {
    this.activeStep = this.userSteps.find((step) => step.state === StepperState.active);
  }

  /**
   * Generates an array of keys for active steps based on given conditions.
   * @param stepConditions Object mapping step keys to a boolean indicating if they are active.
   * @returns Array of keys for steps where the condition is true.
   */
  private generateActiveStepKeys(stepConditions: Step): string[] {
    return Object.keys(stepConditions).filter((key) => stepConditions[key]);
  }

  /**
   * Reorders the activeKeys array to prioritize the 'reservation' step when the 'nopms' condition is true.
   * This method modifies the sequence of steps in the stepper by moving the 'reservation' step to the beginning.
   *
   * @param {string[]} activeKeys - The current array of keys representing active steps in the process.
   * @returns {string[]} The modified array of keys with 'reservation' moved to the front if it exists.
   */
  private reorderStepperForNoPMS(activeKeys: string[]): string[] {
    const reservationIndex = activeKeys.indexOf('reservation');
    if (reservationIndex > -1) {
      // Remove 'reservation' from its current position and add it to the beginning of the array
      // The splice function is used here to remove the element, and unshift is used to insert it at the start
      activeKeys.unshift(...activeKeys.splice(reservationIndex, 1));
    }
    return activeKeys;
  }

  /**
   * Generates the section header and sets additional section details based on the current active step.
   * @param idnow - Optional idnow flag to indicate whether the ID is needed immediately (default: false).
   * @param moduleType - optional string in order to check the current module (Check in or Check out).
   */
  generateSectionHeader(idnow: boolean = false, moduleType: string = '') {
    this.setActiveStepper();
    this.sectionDetails.sectionInfo = '';
    this.sectionDetails.sectionDescription = '';

    switch (this.activeStep.key) {
      case StepEnum.guests:
        this.handleGuestStep(idnow);
        break;
      case StepEnum.reservation:
        if (moduleType === PmsModType.ci) {
          this.handleReservationStep();
        } else {
          this.sectionDetails.sectionHeader = this.globals.translate('misc.overview');
        }
        break;
      case StepEnum.invoice:
        this.sectionDetails.sectionHeader = this.globals.translate('service.folios.folio');
        this.sectionDetails.sectionDescription = this.globals.translate(
          'service.folios.description',
        );
        break;
      case StepEnum.confirm:
        this.sectionDetails.sectionHeader = this.globals.translate('misc.overview');
        break;
    }
  }

  /**
   * Handles setting the section header and details for the reservation-related step.
   */
  private handleReservationStep() {
    const transBase = 'service.check_in.general';
    const currentStep = this.getCurrentStepName();

    if (currentStep === TravelInfoSubSteps.cusBreakfast) {
      this.sectionDetails.sectionHeader = this.cusProducts.breakfast.name;
      this.sectionDetails.sectionDescription = this.cusProducts.breakfast.description;
      return;
    }
    if (currentStep === TravelInfoSubSteps.customFields) {
      this.sectionDetails.sectionHeader = this.globals.translate(
        `${transBase}.additional_information`,
      );
      return;
    }
    this.sectionDetails.sectionHeader = this.globals.translate(`${transBase}.your_reservation`);
  }

  /**
   * Handles setting the section header and details for guest-related steps.
   * @param idnow - Optional flag to indicate whether the ID is needed immediately (default: false).
   */
  private handleGuestStep(idnow: boolean = false) {
    // Default section info for guest steps
    this.sectionDetails.sectionInfo = this.globals.translate(
      'service.check_in.fellows.primary_guest',
    );

    switch (this.subStepForGuest) {
      case GuestSubSteps.primaryGuest:
      case GuestSubSteps.privateAddress:
      case GuestSubSteps.identificationGuest:
        // For these states, the section header varies but the info is the same
        this.sectionDetails.sectionHeader = this.getGuestStepHeader(this.subStepForGuest, idnow);
        break;
      case GuestSubSteps.otherGuest:
        this.sectionDetails.sectionHeader = this.globals.translate(
          'service.check_in.general.private_information',
        ); // Specific header for other guests
        this.sectionDetails.sectionInfo = `${this.activeStep.currentStep} guest`; // Dynamic info based on current step
        break;
    }
  }

  /**
   * Returns the section header text based on the guest sub-step and ID flag.
   * @param subStep - The current guest sub-step.
   * @param idnow - Flag indicating whether the ID is needed immediately.
   * @returns The section header text for the guest-related step.
   */
  private getGuestStepHeader(subStep: GuestSubSteps, idnow: boolean): string {
    // Determine the section header based on the sub-step state
    switch (subStep) {
      case GuestSubSteps.primaryGuest:
        if (idnow) {
          this.sectionDetails.sectionDescription = this.globals.translate(
            'service.check_in.general.nationality_description',
          );
          return this.globals.translate('service.check_in.general.select_nationality');
        } else {
          return this.globals.translate('service.check_in.general.private_information');
        }
      case GuestSubSteps.privateAddress:
        return this.globals.translate('service.check_in.general.private_address');
      case GuestSubSteps.identificationGuest:
        this.sectionDetails.sectionDescription = this.globals.translate(
          'service.check_in.general.identity_verification_des',
        );
        return this.globals.translate('service.check_in.general.identity_verification');
    }
  }

  /**
   * To record the screens. The recording name is used to record the current step in the application.
   */
  setRecording() {
    let name = '';
    switch (this.activeStep.key) {
      case StepEnum.guests:
        name = this.subStepForGuest;
        break;

      case StepEnum.reservation:
        name = this.activeStep.key;
        if (this.activeStep.currentStep !== 1) {
          name += '-cus';
        }
        break;

      default:
        name = this.activeStep.key;
        break;
    }
    // Check if the new name is different from the current value
    if (name && this.globals.viewSubj.getValue() !== name) {
      const mod = (this.genericData?.module || this.globals._module)?.type;
      name = `${mod}-${name}`;
      this.globals.viewSubj.next(name);
    }
  }

  formatDate(dateStr: string): string {
    if (!dateStr) {
      return '';
    }

    const date = moment(dateStr, ['YYYY-MM-DD', 'YYYY.MM.DD', 'YYYY/MM/DD']);
    const isDotFormat = dateStr.includes('.');
    return date.format(isDotFormat ? 'D. MMM' : 'MMM Do');
  }

  formatTime(timeStr: string): string {
    if (!timeStr) {
      return '';
    }

    const time = moment(timeStr, 'HH:mm');
    return time.format('h:mm a');
  }

  /** Set reservation card info(Heading, Extras, CICO Times, arrival/departure dates, Image) */
  setReservationCardInfo(data: any): ReservationCard {
    return {
      heading: data.incident.reservation?.unit?.name,
      description: data.incident.reservation?.unit?.description,
      times: this.setCheckInOutTimes(data),
      dates: this.setCheckInOutDates(data),
      media: this.getMediaUrl(data),
      extras: this.getReservationCardExtras(data),
    };
  }

  /** Format check-in/check-out times for reservation card */
  setCheckInOutTimes(data: any): Times {
    const { check_in_from, check_out_till } = data.incident.reservation.reception;
    return {
      checkInTime: this.formatCheckTime(check_in_from, 'misc.check_in'),
      checkOutTime: this.formatCheckTime(check_out_till, 'misc.check_out'),
    };
  }

  /** Helper function to format check-in/check-out time with translation */
  private formatCheckTime(time: string, translationKey: string): string {
    const translatedText = this.globals.translate(translationKey);
    const formattedTime = this.formatTime(time);
    return `${formattedTime} ${translatedText}`;
  }

  /** Format arrival/departure dates for reservation card */
  setCheckInOutDates(data: any): Dates {
    return {
      arrival: this.formatDate(data.incident.reservation.arrival),
      departure: this.formatDate(data.incident.reservation.departure),
    };
  }

  /** Extract URLs media object */
  getMediaUrl(data: any): string | undefined {
    const mediaArray = data?.incident?.reservation?.medias || [];
    return mediaArray[0]?.media?.url;
  }

  /** Get extras (services) for reservation card */
  getReservationCardExtras(data: any): Extras {
    const services = data.incident.reservation.services;
    return {
      data: services,
      heading: services?.length ? this.globals.translate('booking_data.transportation.extras') : '',
    };
  }

  updateBookedUnit(unit: Unit, medias: any, services: string[]) {
    this.dataSubj.value.reservation?.updateUnit(unit, medias, services);
    this.dataSubj.value.incident.reservation.updateUnit(unit, medias, services);
    this.dataSubj.next(this.dataSubj.value);
  }
}

// Helper Class
export class Step {
  guests: boolean;
  reservation: boolean;
  invoice: boolean;
  confirm: boolean;

  constructor(guests: boolean, reservation: boolean, invoice: boolean, confirm: boolean) {
    this.guests = guests;
    this.reservation = reservation;
    this.invoice = invoice;
    this.confirm = confirm;
  }
}
