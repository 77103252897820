import { DragDropModule } from '@angular/cdk/drag-drop';

import { APP_BASE_HREF, CurrencyPipe, registerLocaleData } from '@angular/common';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
  Meta,
  Title,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import Bugsnag from '@bugsnag/js';

import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SignaturePadModule } from 'angular2-signaturepad';
import { QRCodeModule } from 'angularx-qrcode';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { A2hsModule } from 'common/a2hs/a2hs.module';
import { BackgroundModule } from 'common/background/background.module';
import { BackgroundService } from 'common/background/background.service';
import { ContentModule } from 'common/content/content.module';
import { FooterModule } from 'common/footer/footer.module';
import { FooterService } from 'common/footer/footer.service';
import { HeaderModule } from 'common/header/header.module';

import { InitComponent } from 'common/init/init.component';
import { InternetAccessModule } from 'common/internet_access/internet_access.module';
import { LandingpageComponent } from 'common/landingpage/landingpage.component';
import { LanguageService } from 'common/language/language.service';
import { LoaderModule } from 'common/loader/loader.module';
import { MenuService } from 'common/menu/menu.service';
import { ModalModule } from 'common/modal/modal.module';
import { ModalService } from 'common/modal/modal.service';
import { NavbarModule } from 'common/navbar/navbar.module';
import { NavbarService } from 'common/navbar/navbar.service';
import { SelectionService } from 'common/selection/selection.service';
import { SidebarComponent } from 'common/sidebar/sidebar.component';
import { SidebarService } from 'common/sidebar/sidebar.service';
import { WidgetHeaderModule } from 'common/wizard/header/header.module';
import { environment } from 'environments/environment';

import { Business } from 'models/business';
import { Guest } from 'models/guest';
import { LandingPage } from 'models/landingPage';
import { SharedNewspaperParentModule } from 'modules/api/shared/parent.module';

import { ComplaintModuleService } from 'modules/complaint/complaint.service';
import { CouponModuleService } from 'modules/coupon/coupon.service';
import { EventModuleService } from 'modules/event/event.service';
import { CalendarService } from 'modules/event/list/calendar/calendar.service';
import { FeedbackService } from 'modules/feedback/feedback.service';
import { GroupPortfolioEntryModule } from 'modules/group_portfolio/entry/group_portfolio.module';
import { GroupPortfolioService } from 'modules/group_portfolio/group_portfolio.service';
import { GroupMapModule } from 'modules/group_portfolio/map/map.module';
import { GroupPortfolioModule } from 'modules/group_portfolio/overview/group_portfolio.module';
import { GroupSocialModule } from 'modules/group_portfolio/social/group_social.module';
import { HomeComponent } from 'modules/home/home.component';
import { MenuModuleService } from 'modules/menu/menu.service';
import { NewsModule } from 'modules/news/news.module';
import { NewsService } from 'modules/news/news.service';
import { NewspapersService } from 'modules/newspapers/newspapers.service';
import { OfflineComponent } from 'modules/offline/offline.component';
import { PmsCiCoBaseDirective } from 'modules/pms/base/cico_base.directive';
import { PmsCheckInModule } from 'modules/pms/check_in/check_in.module';
import { DeviceService } from 'services/device.service';
import { PmsCheckOutModule } from './modules/pms/check_out/check_out.module';
import { ConflictModule } from './modules/pms/kiosk/conflict/conflict.module';
import { DebugModule } from 'modules/pms/kiosk/debug/debug.module';
import { PoiModuleService } from 'modules/poi/poi.service';
import { PortfolioModuleService } from 'modules/portfolio/portfolio.service';
import { AllocatorModule } from 'modules/shared/allocator/allocator.module';
import { AvailableComponent } from 'modules/shared/forms/available/available.component';
import { DatesModule } from 'modules/shared/forms/dates/dates.module';
import { FormFieldsModule } from 'modules/shared/forms/fields/fields.module';
import { FormPhoneFieldModule } from 'modules/shared/forms/phone/phone_field.module';
import { FormPlaceModule } from 'modules/shared/forms/place/place.module';
import { FormSelectModule } from 'modules/shared/forms/select/select.module';
import { SendCancelModule } from 'modules/shared/forms/send_cancel/send_cancel.module';
import { AttachmentsModule } from 'modules/shared/layout/attachments/attachments.module';
import { BacklinkModule } from 'modules/shared/layout/backlink/backlink.module';
import { ElementNavigationService } from 'modules/shared/layout/element_navigation/element_navigation.service';
import { MapProtectionModule } from 'modules/shared/layout/map_protection/map_protection.module';
import { ModuleTitleModule } from 'modules/shared/layout/module_title.module';
import { SharedSwiperModule } from 'modules/shared/layout/swiper/swiper.module';
import { ExternalLinkService } from 'modules/shared/link/external/external.service';
import { ListService } from 'modules/shared/list/list.service';
import { ShopModuleService } from 'modules/shop/shop.service';
import { SpaListAllModule } from 'modules/spa/list/all/all.module';
import { SpaModuleService } from 'modules/spa/spa.service';
import { TvStationsService } from 'modules/tv_stations/tv_stations.service';
import { WeatherService } from 'modules/weather/weather.service';
import { WifiService } from 'modules/wifi/wifi.service';
import { WishModuleService } from 'modules/wish/wish.service';
import { CookieService } from 'ngx-cookie-service';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { NgPipesModule } from 'ngx-pipes';
import { SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { PmsPaymentService } from 'payment_service';

import { DatePipe } from 'pipes/date.pipe';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { SafePipeModule } from 'pipes/safe.pipe.module';
import { TimePipe } from 'pipes/time.pipe';
import { PmsService } from 'pms_modules/pms.service';
import { PmsVersionComponent } from 'pms_modules/shared/version/version.component';
import { DoorService } from './modules/pms/kiosk/door/door.service';

import { A2hsService } from 'services/a2hs.service';
import { AwayService } from 'services/away.service';
import { BusinessService } from 'services/business.service';
import { BusinessGroupService } from 'services/business_group.service';
import { ChatbotService } from 'services/chatbot.service';
import { CookiesService } from 'services/cookies.service';
import { GuestService } from 'services/guest.service';
import { LoggerService } from 'services/logger.service';
import { MediaQueryService } from 'services/mediaQuery.service';
import { ModuleService } from 'services/module.service';
import { OfflineService } from 'services/offline.service';
import { PushNotificationService } from 'services/push_notification.service';
import { RouterService } from 'services/router.service';
import { ServiceService } from 'services/service.service';
import { StorageService } from 'services/storage.service';
import { StyleService } from 'services/style.service';
import { VisitService } from 'services/visit.service';
import { EncoderService } from 'services/websocket/encoder.service';
import { JourneyService } from 'services/websocket/journey.service';
import { NavigationService } from 'services/websocket/navigation.service';
import { TeaserService } from 'services/websocket/teaser.service';
import { WebsocketService } from 'services/websocket/websocket.service';
import { KioskService } from 'services/websocket/wizard/kiosk.service';
import { SocialModule } from 'social_module/SocialModul';
import { FacebookProvider } from 'social_providers/FacebookProvider';
import { AuthService, ServiceConfig } from 'social_services/ServiceConfig';
import { StepperService } from 'services/pms/stepper.service';
import { EventAggregatorService } from 'services/events/event-aggregator.service';

import { StraivService } from 'straiv/straiv.service';
import { EmailValidatorModule } from 'validators/email.validator.module';
import { LengthValidatorModule } from 'validators/length.validator.module';

import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { WidgetAdvertService } from 'widgets/advert/advert.service';
import { WidgetCallModule } from 'widgets/call/call.module';
import { WidgetClockModule } from 'widgets/clock/clock.module';
import { WidgetGroupModule } from 'widgets/group/group.module';
import { WidgetGroupBusinessModule } from 'widgets/group_business/group_business.module';
import { GroupBusinessService } from 'widgets/group_business/group_business.service';
import { WidgetGroupImageModule } from 'widgets/group_image/group_image.module';
import { WidgetModuleModule } from 'widgets/module/module.module';
import { WidgetQrCodeModule } from 'widgets/qr_code/qr_code.module';
import { WidgetSearchModule } from 'widgets/search/search.module';
import { WidgetSocialModule } from 'widgets/social/social.module';
import { WidgetSuggestionsModule } from 'widgets/suggestions/suggestions.module';
import { SuggestionsService } from 'widgets/suggestions/suggestions.service';
import { WidgetWeatherModule } from 'widgets/weather/weather.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RestrictionModule } from './common/restriction/restriction.module';
import { ErrorModule } from './error/error.module';
import { CiCoGuard } from './guards/cico.guard';
import { httpInterceptorProviders } from './http-interceptors';
import { PmsKioskModule } from './modules/pms/kiosk/kiosk.module';
import { PmsLoginModule } from './modules/pms/shared/login/login.module';
import { PmsWizardTerminalOptionModule } from './modules/pms/kiosk/option/option.module';
import { PmsKioskTimerModule } from './modules/pms/kiosk/timer/timer.module';
import { MatIconModule } from '@angular/material/icon';
import { PushFormModule } from './modules/push/form/form.module';

registerLocaleData(localeDe, 'de');

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpBackend: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(httpBackend));
}

Bugsnag.start({
  apiKey: environment.bugsnag_id,
  appVersion: environment.version,
  releaseStage: environment.name,
  autoTrackSessions: environment.name !== 'development',
  autoDetectErrors: true,
  collectUserIp: false,
  generateAnonymousId: false,
  enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request', 'user'],
  enabledReleaseStages: [
    'production',
    'demo',
    'ickarus',
    'torted',
    'acid',
    'azure',
    'peet',
    'radius',
  ],
});

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

declare var Hammer: any;

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    return new Hammer(element, {
      touchAction: 'pan-y',
    });
  }
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 1,
};

const CONFIG = new ServiceConfig(
  [
    {
      id: FacebookProvider.PROVIDER_ID,
      provider: new FacebookProvider('271125943502488'),
    },
  ],
  true,
);

export function provideConfig() {
  return CONFIG;
}

@NgModule({
  declarations: [
    InitComponent,
    AppComponent,
    DatePipe,
    OfflineComponent,
    PmsVersionComponent,
    SidebarComponent,
    TimePipe,
    AvailableComponent,
    LandingpageComponent,
    HomeComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    LoadingBarRouterModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    HttpClientModule,
    InternationalPhoneNumberModule,
    NgPipesModule,
    NgSelectModule,
    QRCodeModule,
    SignaturePadModule,
    SocialModule,
    SharedSwiperModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    A2hsModule,
    AllocatorModule,
    AttachmentsModule,
    BackgroundModule,
    BacklinkModule,
    ContentModule,
    DatesModule,
    DebugModule,
    DragDropModule,
    EmailValidatorModule,
    LengthValidatorModule,
    ErrorModule,
    ConflictModule,
    FilterPipeModule,
    FooterModule,
    FormFieldsModule,
    FormPhoneFieldModule,
    FormPlaceModule,
    FormSelectModule,
    FormsModule,
    GroupMapModule,
    GroupPortfolioModule,
    GroupPortfolioEntryModule,
    GroupSocialModule,
    HammerModule,
    HeaderModule,
    InternetAccessModule,
    LoaderModule,
    MapProtectionModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatStepperModule,
    ModalModule,
    ModuleTitleModule,
    NavbarModule,
    NewsModule,
    SafePipeModule,
    SpaListAllModule,
    PushFormModule,
    PmsCheckInModule,
    PmsKioskModule,
    ReactiveFormsModule,
    RestrictionModule,
    SendCancelModule,
    SharedNewspaperParentModule,
    SharedSwiperModule,
    WidgetGroupImageModule,
    WidgetAdvertModule,
    WidgetCallModule,
    WidgetClockModule,
    WidgetGroupBusinessModule,
    WidgetGroupModule,
    WidgetHeaderModule,
    WidgetModuleModule,
    WidgetQrCodeModule,
    WidgetSearchModule,
    WidgetSocialModule,
    WidgetSuggestionsModule,
    WidgetWeatherModule,
    ZXingScannerModule,
    PmsKioskTimerModule,
    PmsLoginModule,
    PmsWizardTerminalOptionModule,
    MatIconModule,
    PmsCheckOutModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    { provide: ServiceConfig, useFactory: provideConfig },
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    A2hsService,
    ApiService,
    AuthService,
    AwayService,
    BackgroundService,
    Business,
    BusinessService,
    BusinessGroupService,
    CalendarService,
    ChatbotService,
    CiCoGuard,
    StraivService,
    ComplaintModuleService,
    CookieService,
    CookiesService,
    CouponModuleService,
    CurrencyPipe,
    DoorService,
    GuestService,
    ElementNavigationService,
    EncoderService,
    EventModuleService,
    ExternalLinkService,
    FeedbackService,
    FooterService,
    Globals,
    GroupPortfolioService,
    GroupBusinessService,
    Guest,
    httpInterceptorProviders,
    JourneyService,
    LandingPage,
    LanguageService,
    ListService,
    LoggerService,
    StorageService,
    DeviceService,
    MediaQueryService,
    MenuService,
    MenuModuleService,
    Meta,
    KioskService,
    ModalService,
    ModuleService,
    NavbarService,
    NavigationService,
    NewspapersService,
    NewsService,
    OfflineService,
    PmsService,
    PmsCiCoBaseDirective,
    PmsCiCoService,
    PmsPaymentService,
    PoiModuleService,
    PortfolioModuleService,
    PushNotificationService,
    RouterService,
    SelectionService,
    ServiceService,
    ShopModuleService,
    SidebarService,
    SpaModuleService,
    StyleService,
    SuggestionsService,
    Title,
    TeaserService,
    ToastrService,
    TvStationsService,
    VisitService,
    WeatherService,
    WebsocketService,
    WidgetAdvertService,
    WifiService,
    WishModuleService,
    StepperService,
    EventAggregatorService,
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
