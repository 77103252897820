export const environment = {
  production: true,
  name: 'torted',
  version: '13.1.0',
  api_url: 'https://api.torted.straiv.io',
  bugsnag_id: '1c578733e7d02db0c8a68f799962553b',
  env: 'staging',
  firebaseServerKey:
    'BFiMkJpzYFEGV7FA6MccoUs0Gi8qExFOz0_gkacSKNEv_R9XlIq0LxMcKjI75pGnpL5Vw-i6-4MqfsPz0ajkOdw',
  cookieKey: 'cookieAccept',
  googleMaps: 'AIzaSyCoQ99r5pjgdki-yk0JU6gmKV8HXsftxkI',
  posthog: {
    enabled: false,
    host: '',
    apiKey: '',
  },
  firebase: {
    apiKey: 'AIzaSyBqSAZlG2i-DMREGywd_N95xR1hQ1C6P3I',
    authDomain: 'code2order-1f7bc.firebaseapp.com',
    databaseURL: 'https://code2order-1f7bc.firebaseio.com',
    projectId: 'code2order-1f7bc',
    storageBucket: 'code2order-1f7bc.appspot.com',
    messagingSenderId: '92700788903',
    appId: '1:92700788903:web:0b89847a51729975487382',
  },
  datadog: {
    enabled: false,
    applicationId: '4368f6f7-ffd3-4261-8e81-b958056814d6',
    clientToken: 'pub2b928269bd3228e220b34089dd9d2704',
  },
  cable_url: 'wss://torted.straiv.io/cable',
};
